<template>
  <div id="video-view">
    <v-card-title class="px-0 mb-5">
      {{ $t('Reports') }}
    </v-card-title>
    <v-row class="mb-5 match-height">
      <v-col
        v-if="hasPermission(87) && false"
        cols="12"
        sm="4"
      >
        <statistics-card-vertical-click
          :change="chartDashboardOptions.change"
          :color="chartDashboardOptions.color"
          :icon="chartDashboardOptions.icon"
          :statistics="chartDashboardOptions.statistics"
          :tooltip="chartDashboardOptions.tooltip"
          :start-title="$t('Dashboard')"
          :subtitle="'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'"
          :show-btn-dots="chartDashboardOptions.showBtnDots"
          :show-change="chartDashboardOptions.showChange"
          @click="onchangeWindow(0)"
        ></statistics-card-vertical-click>
      </v-col>

      <v-col
        v-if="hasPermission(87) && false"
        cols="12"
        sm="4"
      >
        <statistics-card-vertical-click
          :change="chartRecordigActivityOptions.change"
          :color="chartRecordigActivityOptions.color"
          :icon="chartRecordigActivityOptions.icon"
          :statistics="chartRecordigActivityOptions.statistics"
          :tooltip="chartRecordigActivityOptions.tooltip"
          :start-title="$t('Recording Activity')"
          :subtitle="'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'"
          :show-btn-dots="chartRecordigActivityOptions.showBtnDots"
          :show-change="chartRecordigActivityOptions.showChange"
          @click="onchangeWindow(1)"
        ></statistics-card-vertical-click>
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <!-- :subtitle="'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'" -->
        <statistics-card-vertical-click
          :change="chartFanatyOptions.change"
          :color="chartFanatyOptions.color"
          :icon="chartFanatyOptions.icon"
          :statistics="chartFanatyOptions.statistics"
          :tooltip="chartFanatyOptions.tooltip"
          :start-title="'Fanaty'"
          :subtitle="''"
          :show-btn-dots="chartFanatyOptions.showBtnDots"
          :show-change="chartFanatyOptions.showChange"
          @click="onchangeWindow(2)"
        ></statistics-card-vertical-click>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col
        cols="12"
      >
        <v-sheet color="transparent">
          <v-slide-group
            v-model="tab"
            mandatory
            :show-arrows="$vuetify.breakpoint.smAndDown"
            :mobile-breakpoint="$vuetify.breakpoint.thresholds.md + 200"
          >
            <v-slide-item
              v-for="tab in computedTabs"
              :key="tab.icon"
              v-slot="{ active, toggle }"
            >
              <v-btn
                class="mx-2 px-3"
                :input-value="active"
                active-class="secondary white--text"
                depressed
                text
                @click="toggle"
              >
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ tab.icon }}
                </v-icon>
                <span>{{ $t(tab.title) }}</span>
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>

        <v-tabs-items
          id="video-tabs-content"
          v-model="tab"
          class="mt-5 px-2 overflow-visible"
        >
          <v-tab-item
            v-for="tab in computedTabs"
            :key="tab.component"
          >
            <component
              :is="tab.component"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, computed } from '@vue/composition-api'
import {
  mdiViewDashboard,
  mdiVideoOutline,
  mdiHomeGroup,
} from '@mdi/js'
import usePermissions from '@core/utils/usePermissions'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'

// import Dashboard from '@/views/dashboards/dashboard/Dashboard.vue'
import StatisticsCardVerticalClick from '@/@core/components/statistics-card/StatisticsCardVerticalClick.vue'

export default {
  components: {
    // Dashboard,
    StatisticsCardVerticalClick,
  },
  setup() {
    const { hasPermission } = usePermissions()
    const { t } = useUtils()
    const { router } = useRouter()

    const userData = ref({})
    const tab = ref(0)
    const tabs = ref([
      {
        icon: mdiViewDashboard, title: 'Dashboard', show: hasPermission(81), component: 'dashboard',
      },
    ])
    const computedTabs = computed(() => tabs.value.filter(e => e.show))

    // chart windows
    const chartDashboardOptions = ref({
      startTitle: t('statistics.actives_facility'),
      tooltip: 'tooltip',
      icon: mdiViewDashboard,
      color: 'info',
      subtitle: t('statistics.this_month'),
      statistics: '',
      change: '+42%',
      showBtnDots: false,
      showChange: false,
    })

    const chartRecordigActivityOptions = ref({
      startTitle: t('statistics.actives_facility'),
      tooltip: 'tooltip',
      icon: mdiVideoOutline,
      color: 'info',
      subtitle: t('statistics.this_month'),
      statistics: '',
      change: '+42%',
      showBtnDots: false,
      showChange: false,
    })

    const chartFanatyOptions = ref({
      startTitle: t('statistics.actives_facility'),
      tooltip: 'tooltip',
      icon: mdiHomeGroup,
      color: 'info',
      subtitle: t('statistics.this_month'),
      statistics: '',
      change: '+42%',
      showBtnDots: false,
      showChange: false,
    })

    const onchangeWindow = val => {
      let name = 'views-reports-dashboard'
      if (val === 1) name = 'views-reports-recording-activity'
      else if (val === 2) name = 'views-reports-fanaty'

      router.push({
        name,
      })
    }

    return {
      userData,
      tab,
      tabs,
      computedTabs,

      chartDashboardOptions,
      chartRecordigActivityOptions,
      chartFanatyOptions,

      hasPermission,
      onchangeWindow,
    }
  },
}
</script>
<style lang="scss">
.v-btn--active::before {
  opacity: 0 !important;
}
</style>
